<template>
   <div class="ms_main_wrapper">
      <app-side-menu/>
      <app-header/>
      <Suspense>
      <template #default>
        <!-- <div class="ms_content_wrapper padder_top60"> -->
          <app-contents :content_id="content_id" :cat_name="cat_name"/>
        <!-- </div> -->
      </template>
      <template #fallback>
        <app-loader/>
      </template>
    </Suspense>
   </div>
</template>

<script>
// import AppFooter from '../../components/Include/AppFooter.vue'
import AppHeader from '../../components/Include/AppHeader.vue'
import AppSideMenu from '../../components/Include/AppSideMenu.vue'
import AppContents from '../../components/Pages/AppContents.vue'
import AppLoader from '../../components/Spinners/AppLoader.vue'
export default {
  components: { AppSideMenu, AppHeader, AppContents, AppLoader },
   name: 'Contents',
   props: {
    content_id: String,
    cat_name: String
  },
}
</script>

<style>

</style>